import * as d3 from "d3";

export class TabsBar {
    _div_selector;
    _div_selection;
    _tabs_by_id;
    _tabs_list;
    _nav;
    main_content;
    _ul;
    constructor(div_selector) {
        this._div_selector = div_selector;
        this._div_selection = d3.select(this._div_selector);
        this._div_selection.classed("cmk_tab", true);
        this._tabs_by_id = {};
        this._tabs_list = [];
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    initialize(tab?, ifid?: string, vlanid?: string) {
        this._nav = this._div_selection
            .append("nav")
            .attr("role", "navigation")
            .classed("main-navigation", true);
        this.main_content = this._div_selection
            .append("div")
            .classed("main-content", true);

        this._register_tabs();

        this._ul = this._nav.append("ul");
        const a_selection = this._ul
            .selectAll("li")
            .data(this._tabs_list)
            .enter()
            .append("li")
            .each((d, idx, nodes) => {
                d3.select(nodes[idx]).classed(d.tab_id(), true);
            })
            .on("click", event => this._tab_clicked(event))
            .append("a")
            .attr("href", d => "#" + d.tab_id())
            .style("pointer-events", "none");

        a_selection
            .append("span")
            .classed("noselect", true)
            .text(d => d.name());
    }

    _register_tabs() {
        this._get_tab_entries().forEach(tab_class => {
            const new_tab = new tab_class(this);
            new_tab.initialize();
            this._tabs_by_id[new_tab.tab_id()] = new_tab;
            this._tabs_list.push(new_tab);
        });
    }

    get_tab_by_id(tab_id) {
        return this._tabs_by_id[tab_id];
    }

    _get_tab_entries(): (new (tabs_bar: any) => Tab)[] {
        return [];
    }

    _tab_clicked(event) {
        const target = d3.select(event.target);
        const tab = target.datum();
        this._activate_tab(tab);
    }

    _activate_tab(tab) {
        const enable_tab_id = tab.tab_id();

        // Hide all tabs
        this._ul.selectAll("li").classed("active", false);
        this.main_content
            .selectAll(".cmk_tab_element")
            .classed("active", false);

        // Deactivate other tabs
        for (const tab_id in this._tabs_list) {
            if (tab_id == enable_tab_id) continue;

            this._tabs_list[tab_id].deactivate();
        }

        // Enable selected tab
        this._ul.select("li." + enable_tab_id).classed("active", true);
        this.main_content.select("#" + enable_tab_id).classed("active", true);
        tab.activate();
    }
}

export abstract class Tab {
    _tabs_bar;
    _tab_selection;
    constructor(tabs_bar) {
        this._tabs_bar = tabs_bar;
        this._tab_selection = tabs_bar.main_content
            .append("div")
            .attr("id", this.tab_id())
            .classed("cmk_tab_element", true)
            .datum(this);
    }

    // Internal ID
    tab_id(): string {
        alert("tab id missing");
        throw new Error("Method not implemented.");
    }

    // Name in tab index
    name() {
        alert("tab name missing");
    }

    // Called upon instance creation
    abstract initialize(): any;

    // Called when the tab is activated
    abstract activate(): any;

    // Called when the tab is deactivated
    abstract deactivate(): any;
}
