// Copyright (C) 2019 Checkmk GmbH - License: Check_MK Enterprise License
// This file is part of Checkmk (https://checkmk.com). It is subject to the terms and
// conditions defined in the file COPYING, which is part of this source code package.

import * as d3 from "d3";

interface BarData {
    class: string;
    data;
    index?: number;
    sample_date?: Date;
    num_services?: number;
}

const months = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
};

function ts_to_date(unix_timestamp) {
    return new Date(unix_timestamp * 1000);
}

function date_to_ts(date) {
    return date.getTime() / 1000;
}

function format_month_year(the_date) {
    if (the_date == null) {
        return "-";
    } else {
        return months[the_date.getMonth()] + " " + the_date.getFullYear();
    }
}

function format_day_month_year(the_date) {
    if (the_date == null) {
        return "-";
    } else {
        return (
            the_date.getFullYear() +
            "-" +
            (the_date.getMonth() + 1) +
            "-" +
            the_date.getDate()
        );
    }
}

function format_number(number) {
    if (number == null) {
        return "-";
    } else {
        return d3.format("~s")(parseInt(number)) + " Services";
    }
}

function format_num_and_date(data) {
    if (data == null) {
        return "-";
    } else {
        return (
            format_number(data.num_services) +
            " (" +
            format_month_year(ts_to_date(data.sample_time)) +
            ")"
        );
    }
}

function is_subscription_limit(subscription_limit) {
    return subscription_limit !== null && subscription_limit > 0;
}

// Three bars:
//   |###|///|@@@|
//   |###|///|@@@|
//   |###|///|@@@|
//   |###|///|@@@|
//   |###|///|@@@|
// --|---'---'---|--
//  Jan         Feb

// Two and one bars:
//   |#####|/////|           |@@@@@@@@@@@|
//   |#####|/////|           |@@@@@@@@@@@|
//   |#####|/////|           |@@@@@@@@@@@|
//   |#####|/////|           |@@@@@@@@@@@|
//   |#####|/////|           |@@@@@@@@@@@|
// --|-----'-----|-----------|-----------|--
//  Jan         Feb         Mar         Apr

function get_bar_size(x_range, d, bar_dates) {
    const month_len =
        x_range(d3.timeMonth.offset(d.sample_date, 1)) - x_range(d.sample_date);
    const length = bar_dates[get_month_year_key(d.sample_date)];
    return month_len / length;
}

function get_month_year_key(the_date) {
    return the_date.getFullYear() + "-" + the_date.getMonth();
}

function get_tooltip_infos(
    x_coord_date,
    daily_services,
    orig_monthly_averages,
    subscription_start,
    subscription_end,
    subscription_limit
) {
    const the_year = x_coord_date.getFullYear(),
        the_month = x_coord_date.getMonth(),
        the_day = x_coord_date.getDate();

    let num_services_info: string | null = null;
    for (let i = 0; i < daily_services.length; i++) {
        const this_num_service = daily_services[i],
            sample_date = this_num_service.sample_date;
        if (
            sample_date.getFullYear() === the_year &&
            sample_date.getMonth() === the_month &&
            sample_date.getDate() === the_day
        ) {
            num_services_info = format_number(this_num_service.num_services);
            break;
        }
    }

    let average_info: string | null = null;
    for (let i = 0; i < orig_monthly_averages.length; i++) {
        const this_average = orig_monthly_averages[i],
            offset = d3.timeMonth.offset(
                ts_to_date(this_average.sample_time),
                1
            );
        if (this_average.sample_date <= x_coord_date && x_coord_date < offset) {
            average_info = format_number(this_average.num_services);
            break;
        }
    }

    let limit_info: string | null = null;
    if (subscription_limit < 0) {
        limit_info = "Unlimited";
    } else if (subscription_limit !== null) {
        const x_coord_time = date_to_ts(x_coord_date);
        if (
            subscription_start <= x_coord_time &&
            x_coord_time <= subscription_end
        ) {
            limit_info = format_number(subscription_limit);
        }
    }

    return [num_services_info, average_info, limit_info];
}

function add_legend_row(table, data) {
    const row = table.append("tr");
    row.append("td")
        .append("svg")
        .attr("height", 20)
        .attr("width", 20)
        .append("rect")
        .attr("class", data.class_legend_line)
        .attr("x", 0)
        .attr("y", 11)
        .attr("height", 3)
        .attr("width", 20);
    row.append("td")
        .attr("class", "legend-td-extra-space")
        .text(data.title_line);

    row.append("td")
        .append("svg")
        .attr("height", 20)
        .attr("width", 20)
        .append("rect")
        .attr("class", data.class_legend_square)
        .attr("x", 0)
        .attr("y", 2)
        .attr("height", 20)
        .attr("width", 20);
    row.append("td").text(data.title_square + ":");
    row.append("td").text(data.value_square);
}

//// MAIN ////

export function render_detailed_timeline(the_data) {
    // Prepare raw daily_services
    const daily_services = the_data.daily_services.sort(function (a, b) {
        return d3.ascending(a.sample_time, b.sample_time);
    });

    let domain_start_time, domain_end_time;
    if (daily_services.length > 0) {
        domain_start_time = daily_services[0].sample_time;
        domain_end_time = daily_services[daily_services.length - 1].sample_time;
    } else {
        return;
    }

    if (the_data.subscription_start !== null) {
        domain_start_time = Math.min(
            domain_start_time,
            the_data.subscription_start
        );
    }

    if (the_data.subscription_end !== null) {
        domain_end_time = Math.max(domain_end_time, the_data.subscription_end);
    }

    // add a few days before and after for a nicer graph
    const dummy_days = 10 * 24 * 60 * 60;
    const domain_start = ts_to_date(domain_start_time - dummy_days).getTime(),
        domain_end = ts_to_date(domain_end_time + dummy_days).getTime();

    daily_services.forEach(function (d) {
        d.sample_date = ts_to_date(d.sample_time);
        d.num_services = +d.num_services;
    });

    // Prepare monthly averages
    const orig_monthly_averages = the_data.monthly_service_averages;
    const extended_monthly_averages = orig_monthly_averages.slice();

    if (orig_monthly_averages.length > 0) {
        // Add last sample and increase the sample_time by one month
        // in order to draw the last line correctly
        const last_sample =
                orig_monthly_averages[orig_monthly_averages.length - 1],
            the_date = d3.timeMonth.offset(
                ts_to_date(last_sample.sample_time),
                1
            );
        extended_monthly_averages.push({
            sample_time: date_to_ts(the_date),
            num_services: last_sample.num_services,
        });
    }

    extended_monthly_averages.forEach(function (d) {
        d.sample_date = ts_to_date(d.sample_time);
        d.num_services = +d.num_services;
    });

    const margin = {
            top: 40,
            right: 80,
            bottom: 40,
            left: 50,
        },
        width = 0.6 * window.innerWidth - margin.left - margin.right,
        height = 400 - margin.top - margin.bottom;

    const x_range = d3.scaleTime().range([0, width]);
    const y_range = d3.scaleLinear().range([height, 0]);

    const x_axis = d3
        // @ts-ignore
        .axisBottom()
        // @ts-ignore
        .scale(x_range)
        // @ts-ignore
        .ticks(d3.scaleTime.months)
        .tickFormat(function (d) {
            const days = (domain_end - domain_start) / (1000 * 24 * 3600);
            let day_info: string;
            if (days < 30) {
                // @ts-ignore
                day_info = d.getDate() + ". ";
            } else {
                day_info = "";
            }
            // @ts-ignore
            if (d.getMonth() == 0) {
                return format_month_year(d);
            } else {
                // @ts-ignore
                return day_info + months[d.getMonth()];
            }
        });

    const y_axis_left = d3
        // @ts-ignore
        .axisLeft()
        // @ts-ignore
        .scale(y_range)
        .ticks(5)
        // @ts-ignore
        .tickFormat(d3.format("~s"));

    const line_daily_services = d3
        .line()
        .x(function (d) {
            // @ts-ignore
            return x_range(d.sample_date);
        })
        .y(function (d) {
            // @ts-ignore
            return y_range(d.num_services);
        });

    const line_monthly_average_services = d3
        .line()
        .curve(d3.curveStepAfter)
        .x(function (d) {
            // @ts-ignore
            return x_range(d.sample_date);
        })
        .y(function (d) {
            // @ts-ignore
            return y_range(d.num_services);
        });

    const svg = d3
        .select("#detailed_timeline")
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    svg.append("g")
        .append("clipPath")
        .attr("id", "clip")
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", width)
        .attr("height", height);

    const bar_data: BarData[] = [
        {
            class: "legend-highest-usage",
            data: the_data.highest_service_report,
        },
        {
            class: "legend-first-above-subscription",
            data: the_data.subscription_exceeded_first,
        },
        {
            class: "legend-current-usage",
            data: the_data.last_service_report,
        },
    ].filter(function (d) {
        return d.data !== null;
    });

    // Due to this calculcation of bar_dates we adapt the bar sizes:
    // It's possible that all three bars or at least two bars may be
    // placed in the same month.
    const bar_dates = {};
    bar_data.forEach(function (d) {
        const the_date = ts_to_date(d.data.sample_time);
        const key = get_month_year_key(the_date);
        if (!(key in bar_dates)) {
            bar_dates[key] = 0;
        }
        d.index = bar_dates[key];
        bar_dates[key] = bar_dates[key] + 1;
        d.sample_date = the_date;
        d.num_services = +d.data.num_services;
    });

    // Set domains
    x_range.domain([domain_start, domain_end]);
    // @ts-ignore
    y_range.domain([
        0,
        d3.max(daily_services, function (d) {
            // @ts-ignore
            const max_services = Math.max(d.num_services);
            let max_domain: number;
            if (is_subscription_limit(the_data.subscription_limit)) {
                max_domain = Math.max(
                    max_services,
                    the_data.subscription_limit
                );
            } else {
                max_domain = max_services;
            }
            return max_domain + max_domain * 0.05;
        }),
    ]);

    svg.append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(0," + height + ")")
        .call(x_axis);

    svg.append("g")
        .attr("class", "y axis left")
        .call(y_axis_left)
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("dx", (-2 * height) / 5)
        .attr("dy", "-40")
        .text("Services");

    // Order will be "Current", "First", "Highest"
    bar_data.reverse();

    svg.selectAll(".bar")
        .data(bar_data)
        .enter()
        .append("g")
        .attr("class", function (d) {
            return "bar " + d.class;
        })
        .append("rect")
        .attr("clip-path", "url(#clip)")
        .attr("x", function (d) {
            return (
                // @ts-ignore
                x_range(d.sample_date) +
                // @ts-ignore
                d.index * get_bar_size(x_range, d, bar_dates)
            );
        })
        .attr("y", function (d) {
            // @ts-ignore
            return y_range(d.num_services);
        })
        .attr("width", function (d) {
            return get_bar_size(x_range, d, bar_dates);
        })
        .attr("height", function (d) {
            // @ts-ignore
            return height - y_range(d.num_services);
        });

    svg.selectAll(".line-daily-services")
        .data([daily_services])
        .enter()
        .append("g")
        .attr("class", "line-daily-services")
        .append("path")
        .attr("d", function (d) {
            return line_daily_services(d);
        });

    svg.selectAll(".line-monthly-service-averages")
        .data([extended_monthly_averages])
        .enter()
        .append("g")
        .attr("class", "line-monthly-service-averages")
        .append("path")
        .attr("clip-path", "url(#clip)")
        .attr("d", function (d) {
            return line_monthly_average_services(d);
        });

    if (is_subscription_limit(the_data.subscription_limit)) {
        svg.append("g")
            .attr("class", "line-subscription-limit")
            .append("line")
            .attr("x1", x_range(ts_to_date(the_data.subscription_start)))
            .attr("x2", x_range(ts_to_date(the_data.subscription_end)))
            .attr("y1", y_range(the_data.subscription_limit))
            .attr("y2", y_range(the_data.subscription_limit));
    }

    const subscription_start_end = svg
        .selectAll(".subscription-start-end")
        .data([
            {
                x: x_range(ts_to_date(the_data.subscription_start)),
                title: "Subscription period start",
            },
            {
                x: x_range(ts_to_date(the_data.subscription_end)),
                title: "Subscription period end",
            },
        ])
        .enter()
        .append("g")
        .attr("class", "subscription-start-end");

    subscription_start_end
        .append("line")
        .attr("class", "line-subscription-start-end")
        .attr("x1", function (d) {
            return d.x;
        })
        .attr("x2", function (d) {
            return d.x;
        })
        .attr("y1", height)
        .attr("y2", 0);

    subscription_start_end
        .append("text")
        .attr("class", "text-subscription-start-end")
        .attr("x", function (d) {
            return d.x;
        })
        .attr("y", 0)
        .attr("dy", "-10")
        .text(function (d) {
            return d.title;
        });

    const tooltip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip license_usage");
    const pointer_events = svg
        .append("g")
        .attr("class", "pointer-over-effects");
    const h_line = pointer_events.append("path").attr("class", "line-pointer");
    const v_line = pointer_events.append("path").attr("class", "line-pointer");

    pointer_events
        .append("svg:rect")
        .attr("width", width)
        .attr("height", height)
        .attr("fill", "none")
        .attr("pointer-events", "all")
        .on("pointerout", function () {
            tooltip.classed("on", false);
            h_line.classed("on", false);
            v_line.classed("on", false);
        })
        .on("pointerover", function () {
            tooltip.classed("on", true);
            h_line.classed("on", true);
            v_line.classed("on", true);
        })
        .on("pointermove", function () {
            const pointer = d3.pointer(event);
            v_line.classed("on", true).attr("d", function () {
                let d = "M" + pointer[0] + "," + height;
                d += " " + pointer[0] + "," + 0;
                return d;
            });
            h_line.classed("on", true).attr("d", function () {
                let d = "M" + 0 + "," + pointer[1];
                d += " " + x_range(domain_end) + "," + pointer[1];
                return d;
            });

            const x_coord_date = x_range.invert(pointer[0]);
            const tooltip_infos = get_tooltip_infos(
                x_coord_date,
                daily_services,
                orig_monthly_averages,
                the_data.subscription_start,
                the_data.subscription_end,
                the_data.subscription_limit
            );

            tooltip
                .classed("on", true)
                .style("top", (event as MouseEvent).pageY - 10 + "px")
                .style("left", (event as MouseEvent).pageX + 10 + "px")
                .html(
                    "Date: " +
                        format_day_month_year(x_coord_date) +
                        "<br>" +
                        "Services: " +
                        tooltip_infos[0] +
                        "<br>" +
                        "&empty; Services: " +
                        tooltip_infos[1] +
                        "<br>" +
                        "Subscription limit: " +
                        tooltip_infos[2]
                );
        });

    const legend_table = d3
        .select("#detailed_timeline")
        .append("table")
        .attr("class", "legend-table");

    add_legend_row(legend_table, {
        class_legend_line: "legend-subscription-limit",
        title_line: "Subscription sizing",
        class_legend_square: "legend-current-usage",
        title_square: "Current monthly average usage",
        value_square: format_num_and_date(the_data.last_service_report),
    });

    add_legend_row(legend_table, {
        class_legend_line: "legend-monthly-service-averages",
        title_line: "Monthly service averages",
        class_legend_square: "legend-first-above-subscription",
        title_square: "First monthly average usage above subscription limit",
        value_square: format_num_and_date(the_data.subscription_exceeded_first),
    });

    add_legend_row(legend_table, {
        class_legend_line: "legend-daily-services",
        title_line: "Daily services",
        class_legend_square: "legend-highest-usage",
        title_square: "Highest monthly average usage",
        value_square: format_num_and_date(the_data.highest_service_report),
    });
}
