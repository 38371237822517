import * as cmk_figures from "cmk_figures";
import * as cmk_site_overview from "cmk_site_overview";

class AlertOverview extends cmk_site_overview.SiteOverview {
    ident() {
        return "alert_overview";
    }
}

cmk_figures.figure_registry.register(AlertOverview);
