import * as cmk_figures from "cmk_figures";

class InventoryFigure extends cmk_figures.TextFigure {
    ident() {
        return "inventory";
    }

    render() {
        const plot = this._data.plot_definitions[0];
        if (!plot) {
            // rendering before the first ajax call
            return;
        }

        const inventory_data = this._data.data[0];
        this.render_title(this._data.title, this._data.title_url);

        const font_size = cmk_figures.clamp(
            Math.min(this.plot_size.width / 5, (this.plot_size.height * 2) / 3),
            [12, 50]
        );

        const link = this.plot
            .selectAll("a.inventory")
            .data([inventory_data])
            .join("a")
            .classed("inventory", true)
            .attr("xlink:href", d => d.url || null);
        const text = link
            .selectAll("foreignObject")
            .data(d => [d])
            .join("foreignObject")
            .attr("width", "100%")
            .attr("height", "100%")
            .selectAll("div")
            .data(d => [d])
            .join("xhtml:div")
            .selectAll("div")
            .data(d => [d])
            .join("xhtml:div")
            .text(d => d.value)
            .style("font-size", font_size + "px");

        if (inventory_data.unit !== undefined) {
            text.selectAll("span")
                .data(d => [d])
                .join("span")
                .style("font-size", font_size / 2 + "px")
                .style("font-weight", "lighter")
                .text(d => d.unit);
        }
    }
}

cmk_figures.figure_registry.register(InventoryFigure);
